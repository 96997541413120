import {
  showAlert,
  showAlertLong,
  reloadPage,
  showSpinner,
  hideSpinner,
} from '../../utilities/utilities-ui';

export async function validateUpload() {
  // Check file size
  const imageFileInput = document.querySelector(
    '#blog-image-upload-modal #image-file'
  );
  const imageFile = imageFileInput.files[0];
  // Validate file input
  if (imageFile && imageFile.size / 1024 >= 512) {
    imageFileInput.setCustomValidity('File size should be less than 512 kB');
    return;
  } else {
    imageFileInput.setCustomValidity('');
  }
}

export async function uploadBlogImage(event) {
  event.preventDefault();

  const form = new FormData();
  form.append(
    'title',
    document.querySelector('#blog-image-upload-modal #image-title').value
  );
  form.append(
    'fileName',
    document.querySelector('#blog-image-upload-modal #image-file').files[0]
  );

  const uploadBtn = document.querySelector('#blog-image-upload-modal #upload');
  const modalCloseBtn = document.querySelector(
    '#blog-image-upload-modal #blog-image-upload-close'
  );
  showSpinner(uploadBtn);

  // abort in 10 seconds
  let controller = new AbortController();
  const timeoutId = setTimeout(() => {
    controller.abort();
    showAlertLong('danger', 'Request Timeout');
  }, 10000);

  try {
    const response = await fetch(
      `${window.location.origin}/api/v1/blogs/images`,
      {
        signal: controller.signal,
        method: 'POST',
        body: form,
      }
    );

    clearTimeout(timeoutId);

    const result = await response.json();

    if (result.status === 'success') {
      // show alert
      showAlert('success', 'Image uploaded successful');
      // clear form inputs
      document.querySelector('#blog-image-upload-modal form').reset();

      reloadPage();
    } else {
      // show alert
      showAlertLong('danger', 'Error: Image Upload Failed');
    }
  } catch (err) {
    console.log(err);
  } finally {
    hideSpinner(uploadBtn);
    modalCloseBtn.click();
  }
}
