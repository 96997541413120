import { setArtistDetails, submitEnquiryForm } from './enquiryForm-events';

const enquiryModal = document.querySelector('#artist-enquiry-modal');

if (enquiryModal) {
  enquiryModal.addEventListener('shown.bs.modal', setArtistDetails);

  const enquiryForm = document.querySelector('#artist-enquiry-form');

  enquiryForm.addEventListener('submit', submitEnquiryForm);
}
