import {
  showAlert,
  showAlertLong,
  reloadPage,
  redirectTo,
  redirectToLong,
  showSpinner,
  hideSpinner,
} from '../../utilities/utilities-ui';

export function setArtistDetails(event) {
  const artistCodename = document.querySelector(
    '#artist-enquiry-form #enquiry-artist-name'
  );
  artistCodename.value = event.relatedTarget.dataset.codename;
  artistCodename.dataset.artistid = event.relatedTarget.dataset.artistid;

  const genreSelect = document.querySelector(
    '#artist-enquiry-form #enquiry-genre'
  );

  while (genreSelect.options.length > 1) {
    genreSelect.remove(1);
  }

  const artistGenres = event.relatedTarget.dataset.genres.split(',');
  artistGenres.push('Others');

  for (let genre of artistGenres) {
    const newOption = new Option(genre, genre);
    genreSelect.add(newOption, undefined);
  }

  if (!document.getElementById('enq-recaptcha').dataset.recaptchaid) {
    const recaptchaId = grecaptcha.render('enq-recaptcha', {
      sitekey: '6Lcl4SweAAAAACaI_G_9sjzceyvrbVWHuP0iMWG8',
    });
    document.querySelector('#enq-recaptcha').dataset.recaptchaid = recaptchaId;
  }
}

export async function submitEnquiryForm(event) {
  event.preventDefault();

  // 1) Get Form Data
  const captcha = grecaptcha.getResponse(
    document.querySelector('#enq-recaptcha').dataset.recaptchaid
  );

  if (!captcha) {
    const captchaValidation = document.querySelector(
      '#artist-enquiry-form #enquiry-captcha-validation'
    );
    captchaValidation.classList.remove('d-none');
    window.setTimeout(() => {
      captchaValidation.classList.add('d-none');
    }, 5000);
    return;
  }

  // 1.1) Check if the user is a guest user
  const isGuestUser = document.querySelector(
    '#artist-enquiry-form #enquiry-user-name'
  );
  let guestName, guestEmail, guestCountryCode, guestMobile, guestCompany;

  if (isGuestUser) {
    guestName = document
      .querySelector('#artist-enquiry-form #enquiry-user-name')
      .value.trim();
    guestEmail = document
      .querySelector('#artist-enquiry-form #enquiry-email')
      .value.trim();
    guestCountryCode = document.querySelector(
      '#artist-enquiry-form #enquiry-country-code'
    ).value;
    guestMobile = document
      .querySelector('#artist-enquiry-form #enquiry-mobile')
      .value.trim();
    guestCompany = document
      .querySelector('#artist-enquiry-form #enquiry-company')
      .value.trim();
  }

  const projectName = document
    .querySelector('#artist-enquiry-form #enquiry-project-name')
    .value.trim();
  const genre = document.querySelector(
    '#artist-enquiry-form #enquiry-genre'
  ).value;
  const duration = document.querySelector(
    '#artist-enquiry-form #enquiry-duration'
  ).value;
  const durationUnit = document.querySelector(
    '#artist-enquiry-form #enquiry-duration-unit'
  ).value;
  const media = [];
  const mediaCheckboxes = document.querySelectorAll(
    '#artist-enquiry-form .enquiry-media'
  );
  for (let checkbox of mediaCheckboxes) {
    if (checkbox.checked) media.push(checkbox.value);
  }
  const projectDetails = document.querySelector(
    '#artist-enquiry-form #enquiry-project-details'
  ).value;

  const additionalServices = [];
  const serviceCheckboxes = document.querySelectorAll(
    '#artist-enquiry-form .enquiry-services'
  );
  for (let checkbox of serviceCheckboxes) {
    if (checkbox.checked) additionalServices.push(checkbox.value);
  }
  const voiceArtists = [];
  voiceArtists.push(
    document.querySelector('#artist-enquiry-form #enquiry-artist-name').dataset
      .artistid
  );

  // 2) Construct project object
  let project;
  if (isGuestUser) {
    project = {
      guestName,
      guestEmail,
      guestCountryCode,
      guestMobile,
      projectName,
      genre,
      captcha,
    };
    if (guestCompany) project.guestCompany = guestCompany;
  } else {
    project = {
      projectName,
      genre,
      captcha,
    };
  }

  if (duration) {
    project.duration = duration;
    project.durationUnit = durationUnit;
  }
  if (media) {
    project.media = media;
  }
  if (projectDetails) {
    project.projectDetails = projectDetails;
  }
  if (voiceArtists.length !== 0) {
    project.voiceArtists = voiceArtists;
  }
  if (additionalServices) {
    project.additionalServices = additionalServices;
  }
  // 3) Submit Enquiry Form
  // abort in 5 seconds
  let controller = new AbortController();
  const timeoutId = setTimeout(() => {
    controller.abort();
    showAlertLong('danger', 'Request Timeout');
  }, 10000);

  const submitBtn = document.querySelector(
    '#artist-enquiry-form #artist-enquiry-btn'
  );

  showSpinner(submitBtn);

  try {
    const response = await fetch(`${window.location.origin}/api/v1/projects`, {
      signal: controller.signal,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(project),
    });

    clearTimeout(timeoutId);

    const result = await response.json();

    if (result.status === 'success') {
      // clear form inputs
      document.querySelector('#artist-enquiry-form').reset();
      // close enquiry modal
      document
        .querySelector('#artist-enquiry-modal #artist-enquiry-close')
        .click();
      // show alert
      showAlertLong('success', 'Enquiry posted successfully');
    } else {
      // close enquiry modal
      document
        .querySelector('#artist-enquiry-modal #artist-enquiry-close')
        .click();
      // show alert
      showAlertLong(
        'danger',
        'Enquiry could not be posted. Please try again later.'
      );
    }
  } catch (err) {
    console.log(err);
  } finally {
    grecaptcha.reset(
      document.querySelector('#enq-recaptcha').dataset.recaptchaid
    );
    hideSpinner(submitBtn);
  }
}
