import {
  showAlert,
  showAlertLong,
  redirectTo,
  showSpinner,
  hideSpinner,
} from '../../utilities/utilities-ui';

export async function submitCreateBlogForm(event) {
  event.preventDefault();

  const title = document.querySelector('#create-blog #title').value;
  const description = document.querySelector('#create-blog #description').value;
  const heroImage = document.querySelector('#create-blog #hero-image').value;
  const markdown = document.querySelector('#create-blog #markdown').value;

  const newBlog = {
    title,
    description,
    heroImage,
    markdown,
  };

  const submitBtn = document.querySelector('#create-blog #submit-btn');

  showSpinner(submitBtn);

  let controller = new AbortController();
  const timeoutId = setTimeout(() => {
    controller.abort();
    showAlertLong('danger', 'Request Timeout');
  }, 10000);

  try {
    const response = await fetch(`${window.location.origin}/api/v1/blogs/`, {
      signal: controller.signal,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newBlog),
    });

    clearTimeout(timeoutId);

    const result = await response.json();

    if (result.status === 'success') {
      // show alert
      showAlert('success', 'Blog has been posted successfully');
      // clear form inputs
      document.querySelector('#createBlogForm').reset();
      redirectTo('/blogs');
    } else {
      // show alert
      showAlertLong('danger', `${result.data}`);
    }
  } catch (err) {
    console.log(err);
  } finally {
    hideSpinner(submitBtn);
  }
}
