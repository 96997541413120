import {
  redirectTo,
  showAlert,
  showAlertLong,
  showSpinner,
  hideSpinner,
} from '../../utilities/utilities-ui';

export async function changePasswordValidation(event) {
  // event.preventDefault();

  const newPassword = document.querySelector('#changepassword #new-pwd');
  const reNewPassword = document.querySelector('#changepassword #renew-pwd');

  if (newPassword.value !== reNewPassword.value) {
    reNewPassword.setCustomValidity('Both passwords should be exactly same');
  } else {
    reNewPassword.setCustomValidity('');
  }
}

export async function changePassword(event) {
  event.preventDefault();

  const currentPassword = document.querySelector(
    '#changepassword #cur-pwd'
  ).value;
  const newPassword = document.querySelector('#changepassword #new-pwd').value;

  let controller = new AbortController();
  const timeoutId = setTimeout(() => {
    controller.abort();
    showAlertLong('danger', 'Request Timeout');
  }, 10000);

  const submitBtn = document.querySelector('#changepassword-submit');
  showSpinner(submitBtn);

  try {
    const response = await fetch(
      `${window.location.origin}/api/v1/users/updateMyPassword`,
      {
        signal: controller.signal,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          password: newPassword,
          passwordCurrent: currentPassword,
        }),
      }
    );

    clearTimeout(timeoutId);
    const result = await response.json();

    if (result.status === 'success') {
      // show alert
      showAlert('success', 'Password has been changed successfully');
      // clear form inputs
      document.querySelector('#changepassword-form').reset();

      redirectTo('/');
    } else {
      // show alert
      showAlertLong('danger', `${result.data}`);
      // clear form inputs
      document.querySelector('#changepassword-form').reset();
    }
    redirectTo('/');
  } catch (err) {
    console.log(`Error: ${err}`);
  } finally {
    hideSpinner(submitBtn);
  }
}
