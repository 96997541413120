import { copyLink, deleteImage } from './blogImages-events';
const blogImagesPage = document.querySelector('#blog-images');

if (blogImagesPage) {
  const copyLinkBtns = document.querySelectorAll('#blog-images .copy-image');
  const deleteImageBtns = document.querySelectorAll('#blog-images .del-image');

  copyLinkBtns.forEach((copyLinkBtn) => {
    copyLinkBtn.addEventListener('click', copyLink);
  });

  deleteImageBtns.forEach((deleteImageBtn) => {
    deleteImageBtn.addEventListener('click', deleteImage);
  });
}
