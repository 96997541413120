import {
  disableElement,
  enableElement,
  showAlertLong,
} from '../../utilities/utilities-ui';

export async function downloadFile(event) {
  event.preventDefault();

  let targetElement;

  if (event.target.classList.contains('save-path')) {
    targetElement = event.target.parentElement.parentElement;
  } else if (event.target.classList.contains('save-solid')) {
    targetElement = event.target.parentElement;
  } else {
    targetElement = event.target;
  }

  disableElement(targetElement);

  const fileName = targetElement.dataset.filename;
  const artistCodeName = targetElement.dataset.codename;
  const artistGender = targetElement.dataset.gender;
  const sampleLanguage = targetElement.dataset.language;
  const sampleGenre = targetElement.dataset.genre;

  // abort in 10 seconds
  let controller = new AbortController();
  const timeoutId = setTimeout(() => {
    controller.abort();
    showAlertLong('danger', 'Request Timeout');
  }, 10000);

  try {
    const response = await fetch(
      `${window.location.origin}/audio/${fileName}`,
      {
        signal: controller.signal,
        method: 'GET',
      }
    );

    clearTimeout(timeoutId);

    const blob = await response.blob();

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${artistCodeName}-${artistGender}-${sampleLanguage}-${sampleGenre}-${fileName.slice(
      -10
    )}`;
    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
    a.click();
    a.remove();
  } catch (err) {
    showAlertLong('Error: File download failed');
  } finally {
    enableElement(targetElement);
  }
}
