// Incomplete
export function getQueriedArtists(event) {
  event.preventDefault();

  const filterRow2 = document.querySelector('#voiceartists-filter2');

  const genre = document.querySelector(
    '#voiceartists-filters #voiceartists-genres'
  ).value;
  const language = document.querySelector(
    '#voiceartists-filters #voiceartists-languages'
  ).value;
  const gender = document.querySelector(
    '#voiceartists-filters #voiceartists-gender'
  ).value;
  const voiceAge = document.querySelector(
    '#voiceartists-filters #voiceartists-voiceage'
  ).value;
  const voiceQuality = document.querySelector(
    '#voiceartists-filters #voiceartists-voicequality'
  ).value;
  const artistCategory = document.querySelector(
    '#voiceartists-filters #voiceartists-artistcategory'
  ).value;
  const tone = encodeURIComponent(
    document.querySelector('#voiceartists-filters #voiceartists-tone').value
  );

  let queryString = `?page=1${genre ? `&genres=${genre}` : ''}${
    language ? `&language=${language}` : ''
  }${gender ? `&gender=${gender}` : ''}${
    artistCategory ? `&artistCategory=${artistCategory}` : ''
  }`;

  if (!filterRow2.classList.contains('d-none')) {
    queryString += `${voiceAge ? `&voiceAge=${voiceAge}` : ''}${
      voiceQuality ? `&voiceQuality=${voiceQuality}` : ''
    }${tone ? `&tone=${tone}` : ''}`;
  }

  // encode URI
  const url = `${window.location.origin}/voice-artists${queryString}`;

  window.location.assign(url);
}

export function clearFilters(event) {
  event.preventDefault();

  document.querySelector(
    '#voiceartists-filters #voiceartists-genres'
  ).selectedIndex = 0;

  document.querySelector(
    '#voiceartists-filters #voiceartists-languages'
  ).selectedIndex = 0;

  document.querySelector(
    '#voiceartists-filters #voiceartists-gender'
  ).selectedIndex = 0;

  document.querySelector(
    '#voiceartists-filters #voiceartists-voiceage'
  ).selectedIndex = 0;

  document.querySelector(
    '#voiceartists-filters #voiceartists-voicequality'
  ).selectedIndex = 0;

  document.querySelector(
    '#voiceartists-filters #voiceartists-artistcategory'
  ).selectedIndex = 0;

  document.querySelector(
    '#voiceartists-filters #voiceartists-tone'
  ).selectedIndex = 0;

  const filterRow2 = document.querySelector('#voiceartists-filter2');
  if (!filterRow2.classList.contains('d-none')) {
    filterRow2.classList.add('d-none');
  }
}
