import {
  showAlert,
  showAlertLong,
  reloadPage,
  redirectTo,
  redirectToLong,
  showSpinner,
  hideSpinner,
} from '../../utilities/utilities-ui';

export async function formValidator(event) {
  // Check file size
  const resumeInput = document.querySelector(
    '#affiliateRegistrationPage #file-input'
  );
  const resumeFile = resumeInput.files[0];
  // Validate file input
  if (resumeFile && resumeFile.size / (512 * 1024) >= 1) {
    resumeInput.setCustomValidity('File size should be less than 512kB');
    return;
  } else {
    resumeInput.setCustomValidity('');
  }
}

export async function formSubmissionHandler(event) {
  event.preventDefault();

  const form = new FormData();
  form.append(
    'fName',
    document.querySelector('#affiliateRegistrationPage #fName').value
  );
  if (document.querySelector('#affiliateRegistrationPage #lName').value)
    form.append(
      'lName',
      document.querySelector('#affiliateRegistrationPage #lName').value
    );
  form.append(
    'gender',
    document.querySelector('#affiliateRegistrationPage #gender').value
  );
  form.append(
    'email',
    document.querySelector('#affiliateRegistrationPage #email').value
  );
  form.append(
    'countryCode',
    document.querySelector('#affiliateRegistrationPage #country-code').value
  );
  form.append(
    'mobile',
    document.querySelector('#affiliateRegistrationPage #mobile').value
  );
  form.append(
    'resume',
    document.querySelector('#affiliateRegistrationPage #file-input').files[0]
  );
  form.append(
    'captcha',
    grecaptcha.getResponse(
      document.querySelector('#affiliateRegistrationPage #page-recaptcha')
        .dataset.recaptchaid
    )
  );

  // Check Captcha
  if (!form.get('captcha')) {
    const captchaValidation = document.querySelector(
      '#affiliateRegistrationPage #validation'
    );
    captchaValidation.classList.remove('d-none');
    window.setTimeout(() => {
      captchaValidation.classList.add('d-none');
    }, 5000);
    return;
  }

  // show spinner and disable button
  const submitBtn = document.querySelector(
    '#affiliateRegistrationPage #submit-btn'
  );
  showSpinner(submitBtn);

  // abort in 5 seconds
  let controller = new AbortController();
  const timeoutId = setTimeout(() => {
    controller.abort();
    showAlertLong('danger', 'Request Timeout');
  }, 10000);

  try {
    const response = await fetch(
      `${window.location.origin}/api/v1/registration/affiliates`,
      {
        signal: controller.signal,
        method: 'POST',
        body: form,
      }
    );

    clearTimeout(timeoutId);

    const result = await response.json();

    if (result.status === 'success') {
      // show alert
      showAlertLong('success', 'You have registered successful');
      // clear form inputs
      document.querySelector('#affiliateRegistrationPage form').reset();

      redirectToLong('/');
    } else {
      // show alert
      showAlertLong(
        'danger',
        'Error: Registration Form could not be posted. Please try again later.'
      );
    }
  } catch (err) {
    console.log(err);
  } finally {
    grecaptcha.reset(
      document.querySelector('#affiliateRegistrationPage #page-recaptcha')
        .dataset.recaptchaid
    );
    hideSpinner(submitBtn);
  }
}
