import { submitGeneralEnquiry, setService } from './generalEnquiryModal-events';

const generalEnquiryModal = document.querySelector('#general-enquiry-modal');

if (generalEnquiryModal) {
  generalEnquiryModal.addEventListener('shown.bs.modal', setService);

  const enquiryForm = document.querySelector('#general-enquiry-form');

  enquiryForm.addEventListener('submit', submitGeneralEnquiry);
}
