import {
  showAlert,
  showAlertLong,
  reloadPage,
  disableElement,
  enableElement,
} from '../../utilities/utilities-ui';

export async function copyLink(event) {
  event.preventDefault();

  let targetElement;

  if (event.target.classList.contains('path')) {
    targetElement = event.target.parentElement.parentElement;
  } else if (event.target.classList.contains('icon')) {
    targetElement = event.target.parentElement;
  } else {
    targetElement = event.target;
  }

  navigator.clipboard.writeText(targetElement.dataset.slug);
  showAlertLong('success', 'Artist URL copied to clipboard.');
}

export async function deleteImage(event) {
  event.preventDefault();

  const confirm = window.confirm('Do you really want to delete this image?');
  if (!confirm) {
    return;
  }

  let targetElement;

  if (event.target.classList.contains('path')) {
    targetElement = event.target.parentElement.parentElement;
  } else if (event.target.classList.contains('icon')) {
    targetElement = event.target.parentElement;
  } else {
    targetElement = event.target;
  }

  const imageId = targetElement.dataset.id;

  disableElement(targetElement);

  // abort in 10 seconds
  let controller = new AbortController();
  const timeoutId = setTimeout(() => {
    controller.abort();
    showAlertLong('danger', 'Request Timeout');
  }, 10000);

  try {
    const response = await fetch(
      `${window.location.origin}/api/v1/blogs/images/${imageId}`,
      {
        signal: controller.signal,
        method: 'DELETE',
      }
    );

    clearTimeout(timeoutId);

    const result = await response.json();

    if (response.status === 204) {
      // show alert
      showAlert('success', 'Image has been deleted');

      reloadPage();
    } else {
      // show alert
      showAlertLong('danger', `${result.data}`);
    }
  } catch (err) {
    showAlertLong('danger', 'Error: Image deletion failed');
  } finally {
    enableElement(targetElement);
  }
}
