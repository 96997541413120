export function showAlert(type, message) {
  const alert = document.querySelector(`#alert .alert-${type}`);
  alert.innerHTML = message; // data.msg
  alert.classList.remove('d-none');
  window.setTimeout(() => {
    alert.classList.add('d-none');
    alert.innerHTML = '';
  }, 2000);
}

export function showAlertLong(type, message) {
  const alert = document.querySelector(`#alert .alert-${type}`);
  alert.innerHTML = message; // data.msg
  alert.classList.remove('d-none');
  window.setTimeout(() => {
    alert.classList.add('d-none');
    alert.innerHTML = '';
  }, 3000);
}

export function redirectTo(url) {
  window.setTimeout(() => {
    window.location.assign(url);
  }, 2000);
}

export function redirectToLong(url) {
  window.setTimeout(() => {
    window.location.assign(url);
  }, 2000);
}

export function reloadPage() {
  window.setTimeout(() => {
    window.location.assign(window.location.href);
    // location.reload(); // location.reload() does not clear cache
  }, 2000);
}

export function showSpinner(element) {
  // element should be the buttone element
  const submitBtn = element;
  const spinner = element.querySelector('.spinner-border');

  submitBtn.setAttribute('disabled', '');
  spinner.classList.remove('d-none');
}

export function hideSpinner(element) {
  // element should be the buttone element
  const submitBtn = element;
  const spinner = element.querySelector('.spinner-border');

  submitBtn.removeAttribute('disabled');
  spinner.classList.add('d-none');
}

export function disableElement(element) {
  element.setAttribute('disabled', '');
}

export function enableElement(element) {
  element.removeAttribute('disabled');
}
