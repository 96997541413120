import {
  resetPasswordFormValidation,
  submitResetPasswordForm,
} from './resetpassword-events';
const resetpasswordPage = document.querySelector('#resetpassword');

if (resetpasswordPage) {
  // Custom validation before form submission
  const submitBtn = document.querySelector(
    '#resetpassword #resetpassword-submit'
  );
  submitBtn.addEventListener('click', resetPasswordFormValidation);

  const forgotpasswordForm = document.querySelector('#resetpassword form');
  forgotpasswordForm.addEventListener('submit', submitResetPasswordForm);
}
