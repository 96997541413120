import {
  showAlert,
  showAlertLong,
  reloadPage,
  redirectTo,
  showSpinner,
  hideSpinner,
} from '../../utilities/utilities-ui';

export async function updateBlog(event) {
  event.preventDefault();

  const title = document.querySelector('#edit-blog #title').value;
  const description = document.querySelector('#edit-blog #description').value;
  const heroImage = document.querySelector('#edit-blog #hero-image').value;
  const markdown = document.querySelector('#edit-blog #markdown').value;
  const updateDate = document.querySelector('#edit-blog #update-date').checked;

  console.log(heroImage);

  const updatedBlog = {
    title,
    description,
    heroImage,
    markdown,
    updateDate,
  };

  const submitBtn = document.querySelector('#edit-blog #submit-btn');
  const slug = document.querySelector('#edit-blog #blog-heading').dataset.slug;

  showSpinner(submitBtn);

  let controller = new AbortController();
  const timeoutId = setTimeout(() => {
    controller.abort();
    showAlertLong('danger', 'Request Timeout');
  }, 10000);

  try {
    const response = await fetch(
      `${window.location.origin}/api/v1/blogs/${slug}`,
      {
        signal: controller.signal,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedBlog),
      }
    );

    clearTimeout(timeoutId);

    const result = await response.json();

    if (result.status === 'success') {
      // show alert
      showAlert('success', 'Blog has been updated successfully');
      // clear form inputs
      document.querySelector('#createBlogForm').reset();
      redirectTo(`/blogs`);
    } else {
      // show alert
      showAlertLong('danger', `${result.data}`);
    }
  } catch (err) {
    console.log(err);
  } finally {
    hideSpinner(submitBtn);
  }
}
