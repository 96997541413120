export function setupArtistEditForm() {
  const artistCategory = document.querySelector(
    '#artist-edit-form #artist-category'
  ).value;
  const codeNameInput = document.querySelector('#artist-edit-form #codeName');
  const studioArtistCheckBox = document.querySelector(
    '#artist-edit-form #check-studio-artist'
  );
  const genresCheckList = document.querySelectorAll(
    '#artist-edit-form .check-genre'
  );
  const celebrityImageElement = document.querySelector(
    '#artist-edit-form #celebrity-image'
  );
  const celebrityProfileLinkInput = document.querySelector(
    '#artist-edit-form #celebrity-profile-link'
  );

  const celebrityProfileLinkAsterisk = document.querySelector(
    '#artist-edit-form #celebrity-profile-link-asterisk'
  );

  if (['Silver', 'Gold', 'Platinum'].includes(artistCategory)) {
    celebrityImageElement.setAttribute('disabled', '');
    celebrityProfileLinkInput.value = '';
    celebrityProfileLinkInput.setAttribute('disabled', '');

    codeNameInput.removeAttribute('disabled');
    studioArtistCheckBox.removeAttribute('disabled');
    genresCheckList.forEach((checkBox) => {
      checkBox.removeAttribute('disabled');
    });

    if (!celebrityProfileLinkAsterisk.classList.contains('d-none')) {
      celebrityProfileLinkAsterisk.classList.add('d-none');
    }
  } else if (['Celebrity'].includes(artistCategory)) {
    codeNameInput.setAttribute('disabled', '');
    studioArtistCheckBox.setAttribute('disabled', '');
    genresCheckList.forEach((checkBox) => {
      checkBox.setAttribute('disabled', '');
    });

    celebrityImageElement.removeAttribute('disabled');
    celebrityProfileLinkInput.removeAttribute('disabled');

    if (celebrityProfileLinkAsterisk.classList.contains('d-none')) {
      celebrityProfileLinkAsterisk.classList.remove('d-none');
    }
  }
}
