export function validateRegistrationForm() {
  const password = document.querySelector('#register-form #register-password');
  const repassword = document.querySelector(
    '#register-form #register-repassword'
  );

  if (password.value !== repassword.value) {
    repassword.setCustomValidity('Should be exactly same as Password');
  } else {
    repassword.setCustomValidity('');
  }
}
