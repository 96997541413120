import { audioPlayerSetup } from '../../components/audio-player/audio-player';

export function showFilterRow2(event) {
  event.preventDefault();

  const genre = document.querySelector('#voiceartists-genres').value;
  const filterRow2 = document.querySelector('#voiceartists-filter2');

  // Show/hide Filter Row 2
  if (genre === 'Advertising' || genre === 'Corporate-AV') {
    filterRow2.classList.remove('d-none');
  } else {
    filterRow2.classList.add('d-none');
  }

  // Enable/disable Tone Selector
  const toneSelector = document.querySelector('#voiceartists-tone');
  if (genre === 'Advertising') {
    toneSelector.removeAttribute('disabled');
  } else {
    toneSelector.value = '';
    toneSelector.setAttribute('disabled', '');
  }
}

export function voiceartistsPageSetup() {
  const genre = document.querySelector('#voiceartists-genres').value;
  const filterRow2 = document.querySelector('#voiceartists-filter2');

  // Show second row of filters
  if (genre === 'Advertising' || genre === 'Corporate-AV') {
    filterRow2.classList.remove('d-none');
  }

  // Audio players setup
  audioPlayerSetup();
}
