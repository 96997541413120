import {
  redirectTo,
  redirectToLong,
  hideSpinner,
  showAlert,
  showAlertLong,
  showSpinner,
} from '../../utilities/utilities-ui';

export async function resetPasswordFormValidation(event) {
  const password = document.querySelector('#resetpassword #password');
  const repassword = document.querySelector('#resetpassword #repassword');

  if (password.value !== repassword.value) {
    repassword.setCustomValidity('Both passwords should be exactly same');
    return;
  } else {
    repassword.setCustomValidity('');
  }
}

export async function submitResetPasswordForm(event) {
  event.preventDefault();

  const hash = document.querySelector('#resetpassword form').dataset.hash;
  const password = document.querySelector('#resetpassword #password').value;
  // const repassword = document.querySelector('#repassword').value;

  let controller = new AbortController();
  const timeoutId = setTimeout(() => {
    controller.abort();
    showAlertLong('danger', 'Request Timeout');
  }, 10000);

  const submitBtn = document.querySelector('#resetpassword-submit');

  showSpinner(submitBtn);

  try {
    const response = await fetch(
      `${window.location.origin}/api/v1/users/reset-password/${hash}`,
      {
        signal: controller.signal,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          password,
        }),
      }
    );

    clearTimeout(timeoutId);

    const result = await response.json();

    if (result.status === 'success') {
      // show alert
      showAlert('success', 'Password has been reset');
      // clear form inputs
      document.querySelector('#resetpassword form').reset();
      redirectTo('/');
    } else {
      // show alert
      showAlertLong(
        'danger',
        'Password could not be reset. Please try again later.'
      );
      redirectToLong('/');
    }
  } catch (err) {
    console.log(`Error: ${err}`);
  } finally {
    hideSpinner(submitBtn);
  }
}
