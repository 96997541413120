import {
  showAlert,
  showAlertLong,
  reloadPage,
  redirectTo,
  redirectToLong,
  showSpinner,
  hideSpinner,
} from '../../utilities/utilities-ui';

export function otherLanguageCheck(event) {
  event.preventDefault();
  const otherLangCheckbox = document.querySelector(
    '#translatorRegistrationPage #lang-oth-check'
  );
  const otherLangInput = document.querySelector(
    '#translatorRegistrationPage #lang-oth'
  );

  if (otherLangCheckbox.checked) {
    otherLangInput.removeAttribute('disabled');
    otherLangInput.setAttribute('required', '');
  } else {
    otherLangInput.setAttribute('disabled', '');
    otherLangInput.removeAttribute('required');
  }
}

export async function formValidator(event) {
  // Check that atleast one language is selected
  let languages = [];
  let languageCheckBoxes = document.querySelectorAll(
    '#translatorRegistrationPage .lang-checkboxes'
  );

  for (let i = 0; i < languageCheckBoxes.length; i++) {
    if (languageCheckBoxes[i].checked) {
      languages.push(languageCheckBoxes[i].value);
    }
  }

  const firstCheckBox = languageCheckBoxes[0];

  if (languages.length === 0) {
    firstCheckBox.setCustomValidity('Select atleast one language');
    return;
  } else {
    firstCheckBox.setCustomValidity('');
  }

  // Check file size
  const resumeInput = document.querySelector(
    '#translatorRegistrationPage #file-input'
  );
  const resumeFile = resumeInput.files[0];
  // Validate file input
  if (resumeFile && resumeFile.size / (512 * 1024) >= 1) {
    resumeInput.setCustomValidity('File size should be less than 512kB');
    return;
  } else {
    resumeInput.setCustomValidity('');
  }
}

export async function formSubmissionHandler(event) {
  event.preventDefault();

  const form = new FormData();
  form.append(
    'fName',
    document.querySelector('#translatorRegistrationPage #fName').value
  );
  if (document.querySelector('#translatorRegistrationPage #lName').value)
    form.append(
      'lName',
      document.querySelector('#translatorRegistrationPage #lName').value
    );
  form.append(
    'gender',
    document.querySelector('#translatorRegistrationPage #gender').value
  );
  form.append(
    'email',
    document.querySelector('#translatorRegistrationPage #email').value
  );
  form.append(
    'countryCode',
    document.querySelector('#translatorRegistrationPage #country-code').value
  );
  form.append(
    'mobile',
    document.querySelector('#translatorRegistrationPage #mobile').value
  );

  let languageCheckBoxes = document.querySelectorAll(
    '#translatorRegistrationPage .lang-checkboxes'
  );
  for (let languageCheckbox of languageCheckBoxes) {
    if (languageCheckbox.checked && languageCheckbox.value != 'other') {
      form.append('languages[]', languageCheckbox.value);
    }
  }

  // get other languages
  const otherLangCheckbox = document.querySelector(
    '#translatorRegistrationPage #lang-oth-check'
  );
  let otherLanguages = '';
  if (otherLangCheckbox.checked) {
    for (let otherLanguage of document
      .querySelector('#translatorRegistrationPage #lang-oth')
      .value.split(',')) {
      form.append('otherLanguages[]', otherLanguage);
    }
  }
  form.append(
    'resume',
    document.querySelector('#translatorRegistrationPage #file-input').files[0]
  );
  form.append(
    'captcha',
    grecaptcha.getResponse(
      document.querySelector('#translatorRegistrationPage #page-recaptcha')
        .dataset.recaptchaid
    )
  );

  // Check Captcha
  if (!form.get('captcha')) {
    const captchaValidation = document.querySelector(
      '#translatorRegistrationPage #validation'
    );
    captchaValidation.classList.remove('d-none');
    window.setTimeout(() => {
      captchaValidation.classList.add('d-none');
    }, 5000);
    return;
  }

  // show spinner and disable button
  const submitBtn = document.querySelector(
    '#translatorRegistrationPage #submit-btn'
  );
  showSpinner(submitBtn);

  // abort in 5 seconds
  let controller = new AbortController();
  const timeoutId = setTimeout(() => {
    controller.abort();
    showAlertLong('danger', 'Request Timeout');
  }, 10000);

  try {
    const response = await fetch(
      `${window.location.origin}/api/v1/registration/translators`,
      {
        signal: controller.signal,
        method: 'POST',
        body: form,
      }
    );

    clearTimeout(timeoutId);

    const result = await response.json();

    if (result.status === 'success') {
      // show alert
      showAlertLong('success', 'You have registered successful');
      // clear form inputs
      document.querySelector('#translatorRegistrationPage form').reset();

      redirectToLong('/');
    } else {
      // show alert
      showAlertLong(
        'danger',
        'Error: Registration Form could not be posted. Please try again later.'
      );
    }
  } catch (err) {
    console.log(err);
  } finally {
    grecaptcha.reset(
      document.querySelector('#translatorRegistrationPage #page-recaptcha')
        .dataset.recaptchaid
    );
    hideSpinner(submitBtn);
  }
}
