export async function validateCreateArtistForm() {
  if (
    document.querySelector('#artist-creation-form #artist-category').value ===
    'Celebrity'
  ) {
    const celebrityImageElement = document.querySelector(
      '#artist-creation-form #celebrity-image'
    );

    // Validate file input
    if (
      celebrityImageElement.files[0] &&
      celebrityImageElement.files[0].size / 1024 >= 512
    ) {
      celebrityImageElement.setCustomValidity(
        'File size should be less than 512 kB'
      );
      return;
    } else {
      celebrityImageElement.setCustomValidity('');
    }
  }
  const genres = [];
  const genreCheckboxes = document.querySelectorAll(
    '#artist-creation-form .check-genre'
  );

  for (let checkbox of genreCheckboxes) {
    if (checkbox.checked) genres.push(checkbox.value);
  }

  // Validation
  const firstGenreCheckbox = genreCheckboxes[0];

  if (genres.length === 0) {
    firstGenreCheckbox.setCustomValidity('Please select atleast one genre');
    return;
  } else {
    firstGenreCheckbox.setCustomValidity('');
  }

  const languages = [];
  const languageCheckboxes = document.querySelectorAll(
    '#artist-creation-form .check-language'
  );

  for (let checkbox of languageCheckboxes) {
    if (checkbox.checked) languages.push(checkbox.value);
  }

  const firstLanguageCheckbox = languageCheckboxes[0];

  if (languages.length === 0) {
    firstLanguageCheckbox.setCustomValidity(
      'Please select atleast one language'
    );
    return;
  } else {
    firstLanguageCheckbox.setCustomValidity('');
  }
}
