import { audioPlayerSetup } from '../../components/audio-player/audio-player';
import { downloadFile } from './audio-events';
const audioPage = document.querySelector('#audioPage');

if (audioPage) {
  audioPlayerSetup();

  const saveBtns = document.querySelectorAll('#audioPage .btn-dwn');
  saveBtns.forEach((btn) => {
    btn.addEventListener('click', downloadFile);
  });
}
