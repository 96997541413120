import {
  formSubmissionHandler,
  formValidator,
} from './affiliateRegistration-events';

const affiliateRegistrationPage = document.querySelector(
  '#affiliateRegistrationPage'
);

if (affiliateRegistrationPage) {
  const submitBtn = document.querySelector(
    '#affiliateRegistrationPage #submit-btn'
  );
  const form = document.querySelector('#affiliateRegistrationPage form');

  submitBtn.addEventListener('click', formValidator);
  form.addEventListener('submit', formSubmissionHandler);
}
