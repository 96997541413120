import {
  showAlert,
  showAlertLong,
  reloadPage,
  redirectTo,
  showSpinner,
  hideSpinner,
  disableElement,
  enableElement,
} from '../../utilities/utilities-ui';

export function getQueriedProjects(event) {
  event.preventDefault();

  const status = document.querySelector('#status').value;

  let queryString = `?page=1${status ? `&status=${status}` : ''}`;

  const url = `${window.location.origin}/projects${queryString}`;
  window.location.assign(url);
}

export async function updateProjectStatus(event) {
  event.preventDefault();

  const confirm = window.confirm(
    'Do you really want to update the project status?'
  );
  if (!confirm) {
    return;
  }

  let targetElement;

  if (event.target.classList.contains('update-path')) {
    targetElement = event.target.parentElement.parentElement;
  } else if (event.target.classList.contains('update')) {
    targetElement = event.target.parentElement;
  } else {
    targetElement = event.target;
  }

  const projectId = targetElement.dataset.projectid;
  const newStatus = targetElement.previousElementSibling.value;

  disableElement(targetElement);

  // abort in 10 seconds
  let controller = new AbortController();
  const timeoutId = setTimeout(() => {
    controller.abort();
    showAlertLong('danger', 'Request Timeout');
  }, 10000);

  try {
    const response = await fetch(
      `${window.location.origin}/api/v1/projects/${projectId}`,
      {
        signal: controller.signal,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          status: newStatus,
        }),
      }
    );

    clearTimeout(timeoutId);

    if (response.status === 200) {
      // show alert
      showAlert('success', 'Project status updated');
      // Reload Page
      reloadPage();
    } else {
      // show alert
      showAlertLong('danger', 'Project status could not be updated');
    }
  } catch (err) {
    console.error('Error: Couldnot update the project status');
  } finally {
    enableElement(targetElement);
  }
}
