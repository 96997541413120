import {
  showAlert,
  showAlertLong,
  redirectTo,
  showSpinner,
  hideSpinner,
} from '../../utilities/utilities-ui';

export function handleArtistCategoryChange() {
  const artistCategory = document.querySelector(
    '#createArtistPage #artist-category'
  ).value;
  const codeNameInput = document.querySelector('#createArtistPage #codeName');
  const studioArtistCheckBox = document.querySelector(
    '#createArtistPage #check-studio-artist'
  );
  // const genresCheckList = document.querySelectorAll(
  //   '#createArtistPage .check-genre'
  // );
  const celebrityImageElement = document.querySelector(
    '#createArtistPage #celebrity-image'
  );
  const celebrityProfileLinkInput = document.querySelector(
    '#createArtistPage #celebrity-profile-link'
  );

  const celebrityImageAsterisk = document.querySelector(
    '#createArtistPage #celebrity-image-asterisk'
  );

  const celebrityProfileLinkAsterisk = document.querySelector(
    '#createArtistPage #celebrity-profile-link-asterisk'
  );

  if (['Silver', 'Gold', 'Platinum'].includes(artistCategory)) {
    celebrityImageElement.setAttribute('disabled', '');
    celebrityProfileLinkInput.setAttribute('disabled', '');

    codeNameInput.removeAttribute('disabled');
    studioArtistCheckBox.removeAttribute('disabled');
    // genresCheckList.forEach((checkBox) => {
    //   checkBox.removeAttribute('disabled');
    // });

    if (!celebrityImageAsterisk.classList.contains('d-none')) {
      celebrityImageAsterisk.classList.add('d-none');
    }
    if (!celebrityProfileLinkAsterisk.classList.contains('d-none')) {
      celebrityProfileLinkAsterisk.classList.add('d-none');
    }
  } else if (['Celebrity'].includes(artistCategory)) {
    codeNameInput.setAttribute('disabled', '');
    studioArtistCheckBox.setAttribute('disabled', '');
    // genresCheckList.forEach((checkBox) => {
    //   checkBox.setAttribute('disabled', '');
    // });

    celebrityImageElement.removeAttribute('disabled');
    celebrityProfileLinkInput.removeAttribute('disabled');

    if (celebrityImageAsterisk.classList.contains('d-none')) {
      celebrityImageAsterisk.classList.remove('d-none');
    }
    if (celebrityProfileLinkAsterisk.classList.contains('d-none')) {
      celebrityProfileLinkAsterisk.classList.remove('d-none');
    }
  }
}

export async function submitCreateArtistForm(event) {
  // Prevent default submission event
  event.preventDefault();

  // Get artist category
  const artistCategory = document.querySelector(
    '#artist-creation-form #artist-category'
  ).value;

  // CREATE FORM OBJECT
  // instantiate a new form
  const form = new FormData();

  // studio artist checkbox
  if (artistCategory !== 'Celebrity') {
    if (
      document.querySelector('#artist-creation-form #check-studio-artist')
        .checked
    ) {
      form.append('studioArtist', true);
    } else {
      form.append('studioArtist', false);
    }
  }

  // codename input
  if (artistCategory !== 'Celebrity') {
    if (
      document.querySelector('#artist-creation-form #codeName').value?.trim()
    ) {
      form.append(
        'codeName',
        document.querySelector('#artist-creation-form #codeName').value?.trim()
      );
    }
  }

  // first name
  form.append(
    'fName',
    document.querySelector('#artist-creation-form #fName').value?.trim()
  );

  // last name
  if (document.querySelector('#artist-creation-form #lName').value?.trim()) {
    form.append(
      'lName',
      document.querySelector('#artist-creation-form #lName').value?.trim()
    );
  }

  // gender
  form.append(
    'gender',
    document.querySelector('#artist-creation-form #gender').value
  );

  // email
  form.append(
    'email',
    document.querySelector('#artist-creation-form #email')?.value.trim()
  );

  // country code
  form.append(
    'countryCode',
    document.querySelector('#artist-creation-form #country-code').value
  );

  // mobile
  form.append(
    'mobile',
    document.querySelector('#artist-creation-form #mobile').value
  );

  // DOB
  if (document.querySelector('#artist-creation-form #dob').value) {
    form.append(
      'dob',
      document.querySelector('#artist-creation-form #dob').value
    );
  }

  // Location
  form.append(
    'location',
    document.querySelector('#artist-creation-form #location').value?.trim()
  );

  // About
  if (document.querySelector('#artist-creation-form #about').value) {
    form.append(
      'about',
      document.querySelector('#artist-creation-form #about').value?.trim()
    );
  }

  // Artist Category
  form.append('artistCategory', artistCategory);

  // Voice Age
  form.append(
    'voiceAge',
    document.querySelector('#artist-creation-form #voice-age').value
  );

  // Voice Quality
  form.append(
    'voiceQuality',
    document.querySelector('#artist-creation-form #voice-quality').value
  );

  // Genres
  const genreCheckboxes = document.querySelectorAll(
    '#artist-creation-form .check-genre'
  );
  for (let checkbox of genreCheckboxes) {
    if (checkbox.checked) form.append('genres[]', checkbox.value);
  }

  // Rating
  if (document.querySelector('#artist-creation-form #rating').value) {
    form.append(
      'rating',
      document.querySelector('#artist-creation-form #rating').value
    );
  }

  if (artistCategory === 'Celebrity') {
    // Celebrity Image
    form.append(
      'celebrityImage',
      document.querySelector('#createArtistPage #celebrity-image').files[0]
    );

    // Celebrity Profile Link
    form.append(
      'celebrityProfileLink',
      document.querySelector('#createArtistPage #celebrity-profile-link').value
    );
  }

  // Languages
  // const languages = [];
  const languageCheckboxes = document.querySelectorAll(
    '#artist-creation-form .check-language'
  );
  for (let checkbox of languageCheckboxes) {
    if (checkbox.checked) form.append('languages[]', checkbox.value);
  }

  // SUBMIT FORM
  const createArtistBtn = document.querySelector(
    '#createArtistPage #create-artist-btn'
  );

  showSpinner(createArtistBtn);

  let controller = new AbortController();
  const timeoutId = setTimeout(() => {
    controller.abort();
    showAlertLong('danger', 'Request Timeout');
  }, 10000);

  try {
    const response = await fetch(
      `${window.location.origin}/api/v1/voiceArtists`,
      {
        signal: controller.signal,
        method: 'POST',
        body: form,
      }
    );

    clearTimeout(timeoutId);

    const result = await response.json();

    if (result.status === 'success') {
      // show alert
      showAlert('success', 'Artist has been created');
      // clear form inputs
      document.querySelector('#artist-creation-form').reset();
      redirectTo(`/voice-artists/${result.data.id}/edit`);
    } else {
      // show alert
      showAlertLong('danger', `${result.data}`);
    }
  } catch (err) {
    console.log(err);
  } finally {
    hideSpinner(createArtistBtn);
  }
}
