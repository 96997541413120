import {
  loginSubmissionHandler,
  registerSubmissionHandler,
  logout,
  setCaptcha,
} from './header-events.js';

import { validateRegistrationForm } from './header-validation';

const loginForm = document.querySelector('#login-form');

const registerForm = document.querySelector('#register-form');

const logoutBtns = document.querySelectorAll('.header-logout');

if (loginForm) {
  loginForm.addEventListener('submit', loginSubmissionHandler);
}

if (registerForm) {
  const registerBtn = document.querySelector('#register #register-submit');
  const registerModal = document.querySelector('#register');

  registerModal.addEventListener('show.bs.modal', setCaptcha);

  registerBtn.addEventListener('click', validateRegistrationForm);
  registerForm.addEventListener('submit', registerSubmissionHandler);
}

for (logoutBtn of logoutBtns) {
  logoutBtn.addEventListener('click', logout);
}
