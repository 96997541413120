import {
  showAlert,
  showAlertLong,
  reloadPage,
  redirectTo,
  showSpinner,
  hideSpinner,
  disableElement,
  enableElement,
} from '../../utilities/utilities-ui';

export async function deleteBlog(event) {
  const slug = document.querySelector('#blog #delete-btn').dataset.slug;

  const confirm = window.confirm('Do you really want to delete the blog post?');
  if (!confirm) {
    return;
  }

  let controller = new AbortController();
  const timeoutId = setTimeout(() => {
    controller.abort();
    showAlertLong('danger', 'Request Timeout');
  }, 10000);

  try {
    const response = await fetch(
      `${window.location.origin}/api/v1/blogs/${slug}`,
      {
        signal: controller.signal,
        method: 'DELETE',
      }
    );

    clearTimeout(timeoutId);

    if (response.status === 204) {
      // show alert
      showAlert('success', 'Blog post deleted');

      redirectTo('/blogs');
    } else {
      // show alert
      showAlertLong('danger', `${result.data}`);
    }
  } catch (err) {
    console.log(`Error: ${err}`);
  }
}
