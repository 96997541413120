import { favouriteToggle, downloadFile } from './artistCards-events';

export function artistCardsSetup() {
  const favButtons = document.querySelectorAll('#artistCards .btn-fav');
  // favbuttons
  favButtons.forEach((favButton) => {
    favButton.addEventListener('click', favouriteToggle);
  });

  // Save Btns
  const saveBtns = document.querySelectorAll('#artistCards .btn-dwn');
  saveBtns.forEach((btn) => {
    btn.addEventListener('click', downloadFile);
  });

  // const shareBtns = document.querySelectorAll('#artistCards .btn-share');
  // shareBtns.forEach((btn) => {
  //   btn.addEventListener('click', shareLink);
  // });
}
