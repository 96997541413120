import { audioPlayerSetup } from '../../components/audio-player/audio-player';

import {
  downloadFile,
  shareURL,
  // copyContact,
  shareAudioLink,
} from './artist-events';

import { favouriteToggle } from '../../components/artistCards/artistCards-events';

export function artistPageSetup() {
  audioPlayerSetup();

  const shareBtn = document.querySelector('#artistPage .share-icon');
  shareBtn.addEventListener('click', shareURL);

  const sampleShareBtns = document.querySelectorAll('#artistPage .btn-share');
  sampleShareBtns.forEach((btn) => {
    btn.addEventListener('click', shareAudioLink);
  });

  // const callBtn = document.querySelector('#artistPage .artist-call');
  // callBtn.addEventListener('click', copyContact);

  const saveBtns = document.querySelectorAll('#artistPage .btn-dwn');
  saveBtns.forEach((btn) => {
    btn.addEventListener('click', downloadFile);
  });

  // favbuttons
  const favButtons = document.querySelectorAll('#artistPage .btn-fav');
  favButtons.forEach((favButton) => {
    favButton.addEventListener('click', favouriteToggle);
  });

  // more & less
  const collapsibles = document.querySelectorAll('#artistPage .collapse');
  for (let collapsible of collapsibles) {
    collapsible.addEventListener('show.bs.collapse', (event) => {
      // event.preventDefault();
      event.target.previousElementSibling.innerHTML = 'less &laquo;';
    });
    collapsible.addEventListener('hide.bs.collapse', (event) => {
      // event.preventDefault();
      event.target.previousElementSibling.innerHTML = 'more &raquo;';
    });
  }
}
