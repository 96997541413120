export function getArtistFormData() {
  const form = new FormData();
  form.append('fName', document.querySelector('#voiceArtistReg #fName').value);
  if (document.querySelector('#voiceArtistReg #lName').value)
    form.append(
      'lName',
      document.querySelector('#voiceArtistReg #lName').value
    );
  form.append(
    'gender',
    document.querySelector('#voiceArtistReg #gender').value
  );
  form.append('email', document.querySelector('#voiceArtistReg #email').value);
  form.append(
    'countryCode',
    document.querySelector('#voiceArtistReg #country-code').value
  );
  form.append(
    'mobile',
    document.querySelector('#voiceArtistReg #mobile').value
  );
  form.append(
    'captcha',
    grecaptcha.getResponse(
      document.querySelector('#voiceArtistReg #page-recaptcha').dataset
        .recaptchaid
    )
  );

  let languageCheckBoxes = document.querySelectorAll(
    '#voiceArtistReg #languages .form-check-input'
  );
  for (let languageCheckbox of languageCheckBoxes) {
    if (languageCheckbox.checked && languageCheckbox.value !== 'other') {
      form.append('languages[]', languageCheckbox.value);
    }
  }

  // get other languages
  const otherLangCheckbox = document.querySelector(
    '#voiceArtistReg #lang-oth-check'
  );
  let otherLanguages = '';
  if (otherLangCheckbox.checked) {
    for (let otherLanguage of document
      .querySelector('#voiceArtistReg #lang-oth')
      .value.split(',')) {
      form.append('otherLanguages[]', otherLanguage);
    }
  }

  const genreCheckboxes = document.querySelectorAll(
    '#voiceArtistReg #genres .form-check-input'
  );

  for (let genreCheckbox of genreCheckboxes) {
    if (genreCheckbox.checked) {
      form.append('genres[]', genreCheckbox.value);
    }
  }

  const switcher = document.querySelector('#voiceArtistReg #switcher');
  if (switcher.checked) {
    const sampleAudioFileInputs = document.querySelectorAll(
      '#voiceArtistReg .audio-input'
    );
    for (let sampleAudioFile of sampleAudioFileInputs) {
      form.append('sampleAudio', sampleAudioFile.files[0]);
    }
  } else {
    let showreelLinkInputs = document.querySelectorAll(
      '#voiceArtistReg .showreel'
    );
    for (let showreelLink of showreelLinkInputs) {
      if (showreelLink.value && showreelLink.value.trim()) {
        form.append('showreel[]', showreelLink.value);
      }
    }
  }
  return form;
}
