import {
  showAlert,
  showAlertLong,
  reloadPage,
  redirectTo,
  showSpinner,
  hideSpinner,
} from '../../utilities/utilities-ui';

import {
  showSampleAudio,
  showShowreel,
  addShowreelInput,
  removeShowreelInput,
  addSampleAudioInput,
} from './voiceArtistRegistration-ui';

import { getArtistFormData } from './voiceArtistRegistration-data';

export function otherLanguageCheck(event) {
  event.preventDefault();
  const otherLangCheckbox = document.querySelector(
    '#voiceArtistReg #lang-oth-check'
  );
  const otherLangInput = document.querySelector('#voiceArtistReg #lang-oth');

  if (otherLangCheckbox.checked) {
    otherLangInput.removeAttribute('disabled');
    otherLangInput.setAttribute('required', '');
  } else {
    otherLangInput.setAttribute('disabled', '');
    otherLangInput.removeAttribute('required');
  }
}

export function artistSwitcher(event) {
  event.preventDefault();
  if (event.target.checked) {
    showSampleAudio();
  } else {
    showShowreel();
  }
}

export function showreelGroupClickHandler(event) {
  event.preventDefault();
  if (event.target.classList.contains('rem-link')) {
    removeShowreelInput(event.target);
  } else if (event.target.classList.contains('add-link')) {
    addShowreelInput(event.target);
  }
}

export function addSampleAudioClickHandler(event) {
  event.preventDefault();
  addSampleAudioInput(event.target);
}

export function artistSubmissionValidation() {
  let languages = [];
  let languageCheckBoxes = document.querySelectorAll(
    '#languages .form-check-input'
  );
  for (let i = 0; i < languageCheckBoxes.length; i++) {
    if (languageCheckBoxes[i].checked) {
      languages.push(languageCheckBoxes[i].value);
    }
  }

  const firstCheckBox = languageCheckBoxes[0];
  // Validate languages input
  if (languages.length === 0) {
    firstCheckBox.setCustomValidity('Select atleast one language');
    return;
  } else {
    firstCheckBox.setCustomValidity('');
  }

  // Validate mp3 file size, if present
  const switcher = document.querySelector('#voiceArtistReg #switcher');
  if (switcher.checked) {
    Array.prototype.slice
      .call(document.querySelectorAll('#voiceArtistReg .audio-input'))
      .forEach((element) => {
        if (element.files[0].size / (5.2 * 1024 * 1024) >= 1) {
          element.setCustomValidity('File size should be less than 5MB');
          return;
        } else {
          element.setCustomValidity('');
        }
      });
  }
}

export async function artistSubmissionHandler(event) {
  event.preventDefault();

  const form = getArtistFormData();

  if (!form.get('captcha')) {
    const captchaValidation = document.querySelector(
      '#voiceArtistReg #validation'
    );
    captchaValidation.classList.remove('d-none');
    window.setTimeout(() => {
      captchaValidation.classList.add('d-none');
    }, 5000);
    return;
  }

  // show spinner and disable button
  const submitBtn = document.querySelector('#voiceArtistReg #submit-btn');
  showSpinner(submitBtn);

  // abort in 5 seconds
  let controller = new AbortController();
  const timeoutId = setTimeout(() => {
    controller.abort();
    showAlertLong('danger', 'Request Timeout');
  }, 10000);

  try {
    const response = await fetch(
      `${window.location.origin}/api/v1/registration/voiceartists`,
      {
        signal: controller.signal,
        method: 'POST',
        body: form,
      }
    );

    clearTimeout(timeoutId);

    const result = await response.json();

    if (result.status === 'success') {
      // show alert
      showAlert('success', 'You have registered successful');
      // clear form inputs
      document
        .querySelector('#voiceArtistReg #artist-registration-form')
        .reset();

      redirectTo('/');
    } else {
      // show alert
      showAlertLong(
        'danger',
        'Error: Registration Form could not be posted. Please try again later.'
      );
    }
  } catch (err) {
    console.log(err);
  } finally {
    grecaptcha.reset(
      document.querySelector('#voiceArtistReg #page-recaptcha').dataset
        .recaptchaid
    );
    hideSpinner(submitBtn);
  }
}
