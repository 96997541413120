import {
  formSubmissionHandler,
  formValidator,
  otherLanguageCheck,
} from './translatorRegistration-events';

const translatorRegistrationPage = document.querySelector(
  '#translatorRegistrationPage'
);

if (translatorRegistrationPage) {
  const otherLang = document.querySelector(
    '#translatorRegistrationPage #lang-oth-check'
  );

  const submitBtn = document.querySelector(
    '#translatorRegistrationPage #submit-btn'
  );

  const form = document.querySelector(
    '#translatorRegistrationPage #translator-registration-form'
  );

  otherLang.addEventListener('change', otherLanguageCheck);

  submitBtn.addEventListener('click', formValidator);

  form.addEventListener('submit', formSubmissionHandler);
}
