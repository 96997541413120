import { validateCreateArtistForm } from './createartist-data';

import {
  submitCreateArtistForm,
  handleArtistCategoryChange,
} from './createartist-events';

const createArtistPage = document.querySelector('#createArtistPage');

if (createArtistPage) {
  const createArtistBtn = document.querySelector(
    '#createArtistPage #create-artist-btn'
  );

  const createArtistForm = document.querySelector('#artist-creation-form');

  const artistCategorySelection = document.querySelector(
    '#createArtistPage #artist-category'
  );

  artistCategorySelection.addEventListener(
    'change',
    handleArtistCategoryChange
  );

  createArtistBtn.addEventListener('click', validateCreateArtistForm);

  createArtistForm.addEventListener('submit', submitCreateArtistForm);
}
