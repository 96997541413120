import { getQueriedProjects, updateProjectStatus } from './projects-events';

const projectsPage = document.querySelector('#projects');

if (projectsPage) {
  projectsPageSetup();

  const searchBtn = document.querySelector('#search');
  searchBtn.addEventListener('click', getQueriedProjects);
}

function projectsPageSetup() {
  const saveBtns = document.querySelectorAll('#projects .btn-update');

  for (let btn of saveBtns) {
    btn.addEventListener('click', updateProjectStatus);
  }
}
