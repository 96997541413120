import {
  showAlert,
  showAlertLong,
  reloadPage,
  redirectTo,
  redirectToLong,
  showSpinner,
  hideSpinner,
} from '../../utilities/utilities-ui';

export async function setService(event) {
  event.preventDefault();

  // Render Recaptcha
  if (!document.querySelector('#page-recaptcha').dataset.recaptchaid) {
    const recaptchaId = grecaptcha.render('page-recaptcha', {
      sitekey: '6Lcl4SweAAAAACaI_G_9sjzceyvrbVWHuP0iMWG8',
    });
    document.querySelector('#page-recaptcha').dataset.recaptchaid = recaptchaId;
  }

  // Set Service Field
  const serviceSelectElement = document.querySelector(
    '#general-enquiry-form #service'
  );

  serviceSelectElement.value = event.relatedTarget.dataset.service;
}

export async function submitGeneralEnquiry(event) {
  event.preventDefault();

  const captcha = grecaptcha.getResponse(
    document.querySelector('#general-enquiry-form #page-recaptcha').dataset
      .recaptchaid
  );

  if (!captcha) {
    const captchaValidation = document.querySelector(
      '#general-enquiry-form #validation'
    );
    captchaValidation.classList.remove('d-none');
    window.setTimeout(() => {
      captchaValidation.classList.add('d-none');
    }, 5000);
    return;
  }

  // 1.1) Check if the user is a guest user
  const isGuestUser = document.querySelector(
    '#general-enquiry-form #user-name'
  );
  let guestName, guestEmail, guestCountryCode, guestMobile, guestCompany;

  if (isGuestUser) {
    guestName = document
      .querySelector('#general-enquiry-form #user-name')
      .value.trim();
    guestEmail = document
      .querySelector('#general-enquiry-form #email')
      .value.trim();
    guestCountryCode = document.querySelector(
      '#general-enquiry-form #country-code'
    ).value;
    guestMobile = document
      .querySelector('#general-enquiry-form #mobile')
      .value.trim();
    guestCompany = document.querySelector('#general-enquiry-form #company')
      .value
      ? document.querySelector('#general-enquiry-form #company').value.trim()
      : '';
  }

  const projectName = document
    .querySelector('#general-enquiry-form #subject')
    .value.trim();
  const projectDetails = document
    .querySelector('#general-enquiry-form #details')
    .value.trim();
  const service = document.querySelector(
    '#general-enquiry-form #service'
  ).value;

  // 2) Construct project object
  let project;
  if (isGuestUser) {
    project = {
      guestName,
      guestEmail,
      guestCountryCode,
      guestMobile,
      projectName,
      projectDetails,
      service,
      captcha,
    };
    if (guestCompany) project.guestCompany = guestCompany;
  } else {
    project = {
      projectName,
      projectDetails,
      service,
      captcha,
    };
  }

  // 3) Submit Enquiry Form
  // abort in 5 seconds
  let controller = new AbortController();
  const timeoutId = setTimeout(() => {
    controller.abort();
    showAlertLong('danger', 'Request Timeout');
  }, 10000);

  const submitBtn = document.querySelector('#general-enquiry-form #submit');
  showSpinner(submitBtn);

  try {
    const response = await fetch(`${window.location.origin}/api/v1/projects`, {
      signal: controller.signal,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(project),
    });

    clearTimeout(timeoutId);

    const result = await response.json();

    if (result.status === 'success') {
      // clear form inputs
      document.querySelector('#general-enquiry-form').reset();
      // close modal
      const modalCloseBtn = document.querySelector(
        '#general-enquiry-modal #general-enquiry-close'
      );
      if (modalCloseBtn) modalCloseBtn.click();
      // show alert
      showAlertLong('success', 'Enquiry posted successfully');
      // redirect to Home page
      // redirectToLong('/');
    } else {
      // show alert
      showAlertLong(
        'danger',
        'Enquiry could not be posted. Please try again later.'
      );
    }
  } catch (err) {
    console.log(err);
  } finally {
    grecaptcha.reset(
      document.getElementById('page-recaptcha').dataset.recaptchaid
    );
    hideSpinner(submitBtn);
  }
}
