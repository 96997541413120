import { addSampleAudioClickHandler } from './voiceArtistRegistration-events';

export function artistFormSetup() {
  // Switch reset
  const switcher = document.querySelector('#voiceArtistReg #switcher');
  const showreelDiv = document.querySelector('#voiceArtistReg #showreel-group');
  const showreelRequired = document.querySelector(
    '#voiceArtistReg #showreel-required'
  );
  switcher.checked = false;
  showreelDiv.classList.remove('d-none');
  showreelRequired.setAttribute('required', '');

  // Reset recaptcha on any invalid event
  const inputElements = document.querySelectorAll('#voiceArtistReg input');
  const recaptchaId = document.querySelector('#voiceArtistReg #page-recaptcha')
    .dataset.recaptchaid;
  for (let inputElement of inputElements) {
    inputElement.oninvalid = function () {
      grecaptcha.reset(recaptchaId);
    };
  }
}

export function showShowreel() {
  const showreelDiv = document.querySelector('#voiceArtistReg #showreel-group');
  const sampleAudioDiv = document.querySelector(
    '#voiceArtistReg #sampleAudio-group'
  );
  const showreelRequired = document.querySelector(
    '#voiceArtistReg #showreel-required'
  );
  const sampleRequired = document.querySelector('#voiceArtistReg #audio-input');

  showreelDiv.classList.remove('d-none');
  showreelRequired.setAttribute('required', '');
  sampleAudioDiv.classList.add('d-none');
  sampleRequired.removeAttribute('required');
}

export function showSampleAudio() {
  const showreelDiv = document.querySelector('#voiceArtistReg #showreel-group');
  const sampleAudioDiv = document.querySelector(
    '#voiceArtistReg #sampleAudio-group'
  );
  const showreelRequired = document.querySelector(
    '#voiceArtistReg #showreel-required'
  );
  const sampleRequired = document.querySelector('#voiceArtistReg #audio-input');

  showreelDiv.classList.add('d-none');
  showreelRequired.removeAttribute('required');
  sampleAudioDiv.classList.remove('d-none');
  sampleRequired.setAttribute('required', '');
}

export function removeShowreelInput(element) {
  const numOfRows = element.parentElement.parentElement.childElementCount;
  element.parentElement.remove();
  const showreelElements = document.querySelector(
    '#voiceArtistReg #showreel-group'
  ).children;
  for (let i = 0; i < numOfRows - 1; i++) {
    showreelElements[i].firstElementChild.removeAttribute('disabled');
  }
}

export function addShowreelInput(element) {
  const numOfRows = element.parentElement.parentElement.childElementCount;
  if (numOfRows < 3) {
    const newElement = document.createElement('div');
    newElement.className = 'input-group mb-2';

    let childElement = document.createElement('button');
    childElement.className = 'add-link btn btn-outline-primary fw-bold py-1';
    childElement.innerHTML = '+';
    newElement.appendChild(childElement);

    childElement = document.createElement('input');
    childElement.className = 'showreel form-control py-1';
    childElement.setAttribute('type', 'url');
    childElement.setAttribute('placeholder', 'URL');
    childElement.setAttribute('maxlength', '500');
    childElement.oninvalid = function () {
      grecaptcha.reset(
        document.querySelector('#voiceArtistReg #page-recaptcha').dataset
          .recaptchaid
      );
    };
    newElement.appendChild(childElement);

    childElement = document.createElement('button');
    childElement.className = 'rem-link btn btn-outline-primary fw-bold py-1';
    childElement.innerHTML = '-';
    newElement.appendChild(childElement);

    element.parentElement.parentElement.insertBefore(
      newElement,
      element.parentElement.nextSibling
    );
  }
  if (numOfRows === 2) {
    const showreelElements = document.querySelector(
      '#voiceArtistReg #showreel-group'
    ).children;
    for (let i = 0; i < 5; i++) {
      showreelElements[i].firstElementChild.setAttribute('disabled', '');
    }
  }
}

export function addSampleAudioInput(element) {
  const sampleAudioInputGroup = element.parentElement.parentElement;
  const numOfRows = sampleAudioInputGroup.childElementCount;
  if (numOfRows <= 2) {
    const newDiv = document.createElement('div');
    newDiv.className = 'input-file-group input-group w-100 mb-2';

    const newInputElement = document.createElement('input');
    newInputElement.setAttribute('type', 'file');
    newInputElement.setAttribute('accept', 'audio/*');
    newInputElement.className = 'audio-input form-control py-1';
    newInputElement.oninvalid = function () {
      grecaptcha.reset(
        document.querySelector('#voiceArtistReg #page-recaptcha').dataset
          .recaptchaid
      );
    };

    const newButton = document.createElement('button');
    newButton.className =
      'add-file btn btn-outine-secondary fw-bold py-0 border border-secondary';
    newButton.innerHTML = '+';

    newButton.addEventListener('click', addSampleAudioClickHandler);

    newDiv.appendChild(newInputElement);
    newDiv.appendChild(newButton);

    sampleAudioInputGroup.insertBefore(
      newDiv,
      element.parentElement.nextSibling
    );
  }
  if (numOfRows === 2) {
    const addFileButtons = document.querySelectorAll(
      '#voiceArtistReg #sampleAudio-group .add-file'
    );
    for (let i = 0; i < addFileButtons.length; i++) {
      addFileButtons[i].setAttribute('disabled', '');
    }
  }
}
