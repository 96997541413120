import {
  showAlert,
  showAlertLong,
  reloadPage,
  redirectTo,
  showSpinner,
  hideSpinner,
  disableElement,
  enableElement,
} from '../../utilities/utilities-ui';

export async function submitArtistEditForm(event) {
  // Prevent default submission event
  event.preventDefault();

  // Get artist category
  const artistCategory = document.querySelector(
    '#artist-edit-form #artist-category'
  ).value;

  // CREATE FORM OBJECT
  // instantiate a new form
  const form = new FormData();

  // studio artist checkbox
  if (artistCategory !== 'Celebrity') {
    if (
      document.querySelector('#artist-edit-form #check-studio-artist').checked
    ) {
      form.append('studioArtist', true);
    } else {
      form.append('studioArtist', false);
    }
  }

  // Go Live
  form.append(
    'goLive',
    document.querySelector('#artist-edit-form #check-golive').checked
  );

  // codename input
  form.append(
    'codeName',
    document.querySelector('#artist-edit-form #codeName').value?.trim()
  );

  // first name
  form.append(
    'fName',
    document.querySelector('#artist-edit-form #fName').value?.trim()
  );

  // last name
  if (document.querySelector('#artist-edit-form #lName').value?.trim()) {
    form.append(
      'lName',
      document.querySelector('#artist-edit-form #lName').value?.trim()
    );
  }

  // gender
  form.append(
    'gender',
    document.querySelector('#artist-edit-form #gender').value
  );

  // email
  form.append(
    'email',
    document.querySelector('#artist-edit-form #email')?.value.trim()
  );

  // country code
  form.append(
    'countryCode',
    document.querySelector('#artist-edit-form #country-code').value
  );

  // mobile
  form.append(
    'mobile',
    document.querySelector('#artist-edit-form #mobile').value
  );

  // DOB
  if (document.querySelector('#artist-edit-form #dob').value) {
    form.append('dob', document.querySelector('#artist-edit-form #dob').value);
  }

  // Location
  form.append(
    'location',
    document.querySelector('#artist-edit-form #location').value?.trim()
  );

  // About
  if (document.querySelector('#artist-edit-form #about').value) {
    form.append(
      'about',
      document.querySelector('#artist-edit-form #about').value?.trim()
    );
  }

  // Artist Category
  form.append('artistCategory', artistCategory);

  // Voice Age
  form.append(
    'voiceAge',
    document.querySelector('#artist-edit-form #voice-age').value
  );

  // Voice Quality
  form.append(
    'voiceQuality',
    document.querySelector('#artist-edit-form #voice-quality').value
  );

  // Genres
  if (artistCategory !== 'Celebrity') {
    const genreCheckboxes = document.querySelectorAll(
      '#artist-edit-form .check-genre'
    );
    for (let checkbox of genreCheckboxes) {
      if (checkbox.checked) form.append('genres[]', checkbox.value);
    }
  }

  // Rating
  if (document.querySelector('#artist-edit-form #rating').value) {
    form.append(
      'rating',
      document.querySelector('#artist-edit-form #rating').value
    );
  }

  if (artistCategory === 'Celebrity') {
    // Celebrity Image
    if (
      document.querySelector('#artist-edit-form #celebrity-image')?.files[0]
    ) {
      form.append(
        'celebrityImage',
        document.querySelector('#artist-edit-form #celebrity-image').files[0]
      );
    }

    // Celebrity Profile Link
    form.append(
      'celebrityProfileLink',
      document.querySelector('#artist-edit-form #celebrity-profile-link').value
    );
  }

  // Languages
  // const languages = [];
  const languageCheckboxes = document.querySelectorAll(
    '#artist-edit-form .check-language'
  );
  for (let checkbox of languageCheckboxes) {
    if (checkbox.checked) form.append('languages[]', checkbox.value);
  }

  // SUBMIT FORM
  const editArtistBtn = document.querySelector(
    '#artist-edit-form #update-artist-btn'
  );

  const artistId = document.querySelector('#editArtistPage #artist-edit-form')
    .dataset.id;

  showSpinner(editArtistBtn);

  let controller = new AbortController();
  const timeoutId = setTimeout(() => {
    controller.abort();
    showAlertLong('danger', 'Request Timeout');
  }, 10000);

  try {
    const response = await fetch(
      `${window.location.origin}/api/v1/voiceArtists/${artistId}`,
      {
        signal: controller.signal,
        method: 'PATCH',
        body: form,
      }
    );

    clearTimeout(timeoutId);

    const result = await response.json();

    if (result.status === 'success') {
      // show alert
      showAlert('success', 'Artist has been updated');

      reloadPage();
    } else {
      // show alert
      showAlertLong('danger', `${result.data}`);
    }
  } catch (err) {
    console.log(err);
  } finally {
    hideSpinner(editArtistBtn);
  }
}

export async function audioClipGenreChange(event) {
  event.preventDefault();

  const audioClipGenre = document.querySelector(
    '#editArtistPage #addAudioClipForm #add-genre'
  ).value;
  const audioClipTone = document.querySelector(
    '#editArtistPage #addAudioClipForm #add-tone'
  );

  if (audioClipGenre === 'Advertising') {
    audioClipTone.removeAttribute('disabled');
    audioClipTone.setAttribute('required', '');
  } else {
    if (!audioClipTone.hasAttribute('disabled')) {
      audioClipTone.setAttribute('disabled', '');
    }
    if (audioClipTone.hasAttribute('required')) {
      audioClipTone.removeAttribute('required');
    }
  }
}

export async function deleteArtist(event) {
  event.preventDefault();

  const confirm = window.confirm('Do you really want to delete the artist?');
  if (!confirm) {
    return;
  }

  const deleteArtistBtn = document.querySelector(
    '#editArtistPage #delete-artist-btn'
  );

  disableElement(deleteArtistBtn);

  const artistId = document.querySelector('#editArtistPage #artist-edit-form')
    .dataset.id;

  let controller = new AbortController();
  const timeoutId = setTimeout(() => {
    controller.abort();
    showAlertLong('danger', 'Request Timeout');
  }, 10000);

  try {
    const response = await fetch(
      `${window.location.origin}/api/v1/voiceArtists/${artistId}`,
      {
        signal: controller.signal,
        method: 'DELETE',
      }
    );

    clearTimeout(timeoutId);

    if (response.status === 204) {
      // show alert
      showAlert('success', 'Artist has been deleted');

      redirectTo('/voice-artists');
    } else {
      // show alert
      showAlertLong('danger', `${await response.json().data}`);
    }
  } catch (err) {
    console.log(`Error: ${err}`);
  } finally {
    enableElement(deleteArtistBtn);
  }
}

export async function addAudioClip(event) {
  event.preventDefault();

  const artistId = event.target.dataset.id;

  const form = new FormData();

  const audioClip = document.querySelector('#addAudioClipForm #add-audio-clip')
    .files[0];
  form.append('audioClip', audioClip);

  const sampleRating = document.querySelector(
    '#addAudioClipForm #sample-rating'
  ).value;
  if (sampleRating) {
    form.append('sampleRating', sampleRating);
  }

  const language = document.querySelector(
    '#addAudioClipForm #add-language'
  ).value;
  form.append('language', language);

  const genre = document.querySelector('#addAudioClipForm #add-genre').value;
  form.append('genre', genre);

  if (genre === 'Advertising') {
    const tone = document.querySelector('#addAudioClipForm #add-tone').value;
    form.append('tone', tone);
  }

  const tags = document.querySelector('#addAudioClipForm #add-tags').value;
  if (tags !== '') {
    for (const tag of tags.split(',')) {
      form.append('tags[]', tag);
    }
  }

  let controller = new AbortController();
  const timeoutId = setTimeout(() => {
    controller.abort();
    showAlertLong('danger', 'Request Timeout');
  }, 10000);

  const addAudioClipBtn = document.querySelector(
    '#addAudioClipForm #add-clip-btn'
  );

  disableElement(addAudioClipBtn);

  try {
    const response = await fetch(
      `${window.location.origin}/api/v1/voiceArtists/${artistId}/audioClips`,
      {
        signal: controller.signal,
        method: 'POST',
        body: form,
      }
    );

    clearTimeout(timeoutId);

    const result = await response.json();
    if (result.status === 'success') {
      // show alert
      showAlert('success', 'Audio uploaded');
      // Reset Form
      document.querySelector('#addAudioClipForm').reset();
      // Reload Page
      reloadPage();
    } else {
      // show alert
      showAlertLong('danger', result.data);
    }
  } catch (err) {
    console.log(`Error: ${err}`);
  } finally {
    enableElement(addAudioClipBtn);
  }
}

export async function deleteAudioClip(event) {
  event.preventDefault();

  const confirm = window.confirm(
    'Do you really want to delete the audio clip?'
  );
  if (!confirm) {
    return;
  }

  let targetElement;
  if (event.target.classList.contains('delete-clip')) {
    targetElement = event.target;
  } else if (event.target.classList.contains('trash-solid')) {
    targetElement = event.target.parentElement;
  } else if (event.target.classList.contains('trash-solid-path')) {
    targetElement = event.target.parentElement.parentElement;
  }

  const artistId = document.querySelector('#editArtistPage #artist-edit-form')
    .dataset.id;

  const clipId = targetElement.dataset.id;

  let controller = new AbortController();
  const timeoutId = setTimeout(() => {
    controller.abort();
    showAlertLong('danger', 'Request Timeout');
  }, 10000);

  disableElement(targetElement);

  try {
    const response = await fetch(
      `${window.location.origin}/api/v1/voiceArtists/${artistId}/audioClips/${clipId}`,
      {
        signal: controller.signal,
        method: 'DELETE',
      }
    );

    clearTimeout(timeoutId);

    if (response.status === 204) {
      // show alert
      showAlert('success', 'Audio Clip has been deleted');
      // Reload page
      reloadPage();
    } else {
      // show alert
      showAlertLong('danger', await response.json().data);
    }
  } catch (err) {
    console.log(`Error: ${err}`);
  } finally {
    enableElement(targetElement);
  }
}
