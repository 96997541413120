import {
  disableElement,
  enableElement,
  showAlertLong,
} from '../../utilities/utilities-ui';

export async function favouriteToggle(event) {
  event.preventDefault();

  let targetElement;

  if (event.target.classList.contains('heart-path')) {
    targetElement = event.target.parentElement.parentElement;
  } else if (event.target.classList.contains('heart')) {
    targetElement = event.target.parentElement;
  } else {
    targetElement = event.target;
  }

  disableElement(targetElement);

  const artistId = targetElement.dataset.id;

  // abort in 10 seconds
  let controller = new AbortController();
  const timeoutId = setTimeout(() => {
    controller.abort();
  }, 10000);

  try {
    if (targetElement.dataset.favourite === 'false') {
      const response = await fetch(
        `${window.location.origin}/api/v1/users/favouriteArtist/${artistId}`,
        {
          signal: controller.signal,
          method: 'POST',
        }
      );

      clearTimeout(timeoutId);

      const result = await response.json();

      if (result.status === 'success') {
        targetElement.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" class="heart h-5 w-5" viewBox="0 0 20 20" fill="#d6293e">
        <path class="heart-path" fill-rule="evenodd" d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z" clip-rule="evenodd" />
        </svg>`;
        targetElement.dataset.favourite = 'true';
      }
    } else {
      const response = await fetch(
        `${window.location.origin}/api/v1/users/favouriteArtist/${artistId}`,
        {
          signal: controller.signal,
          method: 'DELETE',
        }
      );

      if (response.ok) {
        targetElement.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" class="heart h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="#d6293e" stroke-width="2">
        <path class="heart-path" stroke-linecap="round" stroke-linejoin="round" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
        </svg>`;
        targetElement.dataset.favourite = 'false';
      }
    }
  } catch (err) {
    console.error('Error: Not able to toggle');
  } finally {
    enableElement(targetElement);
  }
}

export async function downloadFile(event) {
  event.preventDefault();

  let targetElement;

  if (event.target.classList.contains('save-path')) {
    targetElement = event.target.parentElement.parentElement;
  } else if (event.target.classList.contains('save-solid')) {
    targetElement = event.target.parentElement;
  } else {
    targetElement = event.target;
  }

  disableElement(targetElement);

  const fileName = targetElement.dataset.filename;
  const artistCodeName = targetElement.dataset.codename;
  const artistCategory = targetElement.dataset.artistcategory;
  const artistGender = targetElement.dataset.gender;
  const sampleLanguage = targetElement.dataset.language;
  const sampleGenre = targetElement.dataset.genre;
  const sampleTags = targetElement.dataset.tags;

  // abort in 10 seconds
  let controller = new AbortController();
  const timeoutId = setTimeout(() => {
    controller.abort();
  }, 10000);

  try {
    const response = await fetch(
      `${window.location.origin}/audio/${fileName}`,
      {
        signal: controller.signal,
        method: 'GET',
      }
    );

    clearTimeout(timeoutId);

    const blob = await response.blob();

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${artistCodeName}-${artistCategory}-${artistGender}-${sampleLanguage}-${sampleGenre}-${sampleTags}-${fileName.slice(
      -10
    )}`;
    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
    a.click();
    a.remove();
  } catch (err) {
    showAlertLong('Error: File download failed');
  } finally {
    enableElement(targetElement);
  }
}
