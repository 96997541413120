import { uploadBlogImage, validateUpload } from './blogImageUpload-events';

const blogImageUploadModal = document.querySelector('#blog-image-upload-modal');

if (blogImageUploadModal) {
  const uploadBtn = document.querySelector('#blog-image-upload-modal #upload');
  const imageUploadForm = document.querySelector(
    '#blog-image-upload-modal #blog-image-upload-form'
  );

  uploadBtn.addEventListener('click', validateUpload);

  imageUploadForm.addEventListener('submit', uploadBlogImage);
}
