import { submitForgotPasswordForm } from './forgotpassword-events';
const forgotpasswordPage = document.querySelector('#forgotpassword');

if (forgotpasswordPage) {
  // setupPage();

  const forgotpasswordForm = document.querySelector('#forgotpassword form');

  forgotpasswordForm.addEventListener('submit', submitForgotPasswordForm);
}
