import { artistPageSetup } from './artist-ui';

import { submitEnquiryForm } from '../../components/enquiryForm/enquiryForm-events';

const artistPage = document.querySelector('#artistPage');

if (artistPage) {
  artistPageSetup();

  const enquiryForm = document.querySelector('#artist-enquiry-form');
  if (enquiryForm) {
    enquiryForm.addEventListener('submit', submitEnquiryForm);
  }
}
