import {
  deleteArtist,
  submitArtistEditForm,
  addAudioClip,
  deleteAudioClip,
  audioClipGenreChange,
} from './artistEdit-events';

import { setupArtistEditForm } from './artistEdit-ui';

import { validateArtistEditForm } from './artistEdit-validation';

import { audioPlayerSetup } from '../../components/audio-player/audio-player';

const editArtistPage = document.querySelector('#editArtistPage');

if (editArtistPage) {
  audioPlayerSetup();

  const updateArtistBtn = document.querySelector(
    '#editArtistPage #update-artist-btn'
  );

  const deleteArtistBtn = document.querySelector(
    '#editArtistPage #delete-artist-btn'
  );

  const artistEditForm = document.querySelector('#artist-edit-form');

  // setup form on page load
  setupArtistEditForm();

  const addAudioClipForm = document.querySelector(
    '#editArtistPage #addAudioClipForm'
  );

  const addAudioClipGenre = document.querySelector(
    '#editArtistPage #addAudioClipForm #add-genre'
  );

  addAudioClipGenre.addEventListener('change', audioClipGenreChange);

  const deleteAudioClipBtns = document.querySelectorAll(
    '#editArtistPage .delete-clip'
  );

  addAudioClipForm.addEventListener('submit', addAudioClip);

  for (let btn of deleteAudioClipBtns) {
    btn.addEventListener('click', deleteAudioClip);
  }

  deleteArtistBtn.addEventListener('click', deleteArtist);

  updateArtistBtn.addEventListener('click', validateArtistEditForm);

  artistEditForm.addEventListener('submit', submitArtistEditForm);
}
