import { getQueriedArtists, clearFilters } from './voiceartists-events';
import { showFilterRow2, voiceartistsPageSetup } from './voiceartists-ui';

const voiceartistsPage = document.getElementById('voiceartists');

if (voiceartistsPage) {
  const searchBtn = document.querySelector('#voiceartists .right #btn-search');
  const clearBtn = document.querySelector('#voiceartists .right #btn-clear');
  const genreFilterSelection = document.querySelector('#voiceartists-genres');

  voiceartistsPageSetup();

  searchBtn.addEventListener('click', getQueriedArtists);
  clearBtn.addEventListener('click', clearFilters);
  genreFilterSelection.addEventListener('change', showFilterRow2);
}
