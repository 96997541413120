import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import Tooltip from 'bootstrap/js/dist/tooltip';

import './components/header/header-index';
import './components/artistCards/artistCards-index';
import './components/enquiryForm/enquiryForm-index';
import './components/generalEnquiryModal/generalEnquiryModal-index';
import './components/blogImageUpload/blogImageUpload-index';

import './pages/home/home-index';
import './pages/voiceartists/voiceartists-index';
import './pages/artist/artist-index';
import './pages/audio/audio-index';
import './pages/forgotpassword/forgotpassword-index';
import './pages/resetpassword/resetpassword-index';
import './pages/changepassword/changepassword-index';
import './pages/createartist/createartist-index';
import './pages/artistEdit/artistEdit-index';
import './pages/projects/projects-index';
import './pages/contactus/contactus-index';
import './pages/voiceArtistRegistration/voiceArtistRegistration-index';
import './pages/affiliateRegistration/affiliateRegistration-index';
import './pages/translatorRegistration/translatorRegistration-index';
import './pages/blogImages/blogImages-index';
import './pages/createBlog/createBlog-index';
import './pages/blog/blog-index';
import './pages/editBlog/editBlog-index';
import './pages/favouriteArtists/favouriteArtists-index';
import './pages/landingpage/landingpage-inex';

// import './pages/home/home-index';

// import scss file
import '../scss/main.scss';

// Enable Bootstrap Tooltips
const tooltipTriggerElements = document.querySelectorAll(
  '[data-bs-toggle="tooltip"]'
);

for (let element of tooltipTriggerElements) {
  new Tooltip(element);
}
