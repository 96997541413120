import {
  changePasswordValidation,
  changePassword,
} from './changepassword-events';

const changePasswordPage = document.querySelector('#changepassword');

if (changePasswordPage) {
  const changePasswordSubmitBtn = document.querySelector(
    '#changepassword #changepassword-submit'
  );
  const changePasswordForm = document.querySelector(
    '#changepassword #changepassword-form'
  );

  changePasswordSubmitBtn.addEventListener('click', changePasswordValidation);

  changePasswordForm.addEventListener('submit', changePassword);
}
