import { artistFormSetup } from './voiceArtistRegistration-ui';
import {
  otherLanguageCheck,
  artistSwitcher,
  showreelGroupClickHandler,
  addSampleAudioClickHandler,
  artistSubmissionValidation,
  artistSubmissionHandler,
} from './voiceArtistRegistration-events';

const voiceArtistRegistrationPage = document.querySelector('#voiceArtistReg');

if (voiceArtistRegistrationPage) {
  artistFormSetup();

  // Other language
  const otherLang = document.querySelector('#voiceArtistReg #lang-oth-check');
  otherLang.addEventListener('change', otherLanguageCheck);

  // Switch between Showreel and file
  const switcher = document.querySelector('#voiceArtistReg #switcher');
  switcher.addEventListener('change', artistSwitcher);

  // add and remove showreel URL input fields
  const showreelGroup = document.querySelector(
    '#voiceArtistReg #showreel-group'
  );
  showreelGroup.addEventListener('click', showreelGroupClickHandler);

  // add sample audio file input fields
  const sampleAudioGroup = document.querySelector('#voiceArtistReg #add-file');
  sampleAudioGroup.addEventListener('click', addSampleAudioClickHandler);

  // Custom validation before form submission
  const submitBtn = document.querySelector('#voiceArtistReg #submit-btn');
  submitBtn.addEventListener('click', artistSubmissionValidation);

  // Handle voice artist registration form submit event
  const artistRegistrationForm = document.querySelector(
    '#voiceArtistReg #artist-registration-form'
  );
  artistRegistrationForm.addEventListener('submit', artistSubmissionHandler);
}
