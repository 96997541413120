import {
  showAlert,
  showAlertLong,
  reloadPage,
  redirectTo,
  showSpinner,
  hideSpinner,
} from '../../utilities/utilities-ui';

export async function loginSubmissionHandler(event) {
  event.preventDefault();

  const submitBtn = document.querySelector('#login-form #login-submit');
  showSpinner(submitBtn);

  const email = document.querySelector('#login-form #login-email').value;
  const password = document.querySelector('#login-form #login-password').value;

  // abort in 5 seconds
  let controller = new AbortController();
  const timeoutId = setTimeout(() => {
    controller.abort();
    showAlertLong('danger', 'Request Timeout');
  }, 10000);

  try {
    const response = await fetch(
      `${window.location.origin}/api/v1/users/login`,
      {
        // signal: controller.signal,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      }
    );
    clearTimeout(timeoutId);

    const result = await response.json();

    if (result.status === 'success') {
      // clear form inputs
      document.querySelector('#login-form').reset();
      // close login form
      document.querySelector('#login .btn-close').click();
      // show alert
      showAlert('success', 'Login Successful');
      // reload page
      reloadPage();
    } else if (response.status === 401) {
      // clear password from login form
      document.querySelector('#login #login-password').value = '';
      // close login form
      document.querySelector('#login .btn-close').click();
      // show alert
      showAlertLong(
        'danger',
        'Login Unsuccessful: Username or Password is incorrect'
      );
    } else {
      // clear password from login form
      document.querySelector('#login #login-password').value = '';
      // close login form
      document.querySelector('#login .btn-close').click();
      // show alert
      showAlertLong('danger', 'Login Unsuccessful');
    }
  } catch (err) {
    // clear password from login form
    document.querySelector('#login #login-password').value = '';
    // close login form
    document.querySelector('#login .btn-close').click();
    // show alert
    showAlertLong('danger', 'Error: Login Failed');
    // reload page
    reloadPage();
  } finally {
    hideSpinner(submitBtn);
  }
}

export async function registerSubmissionHandler(event) {
  event.preventDefault();

  const submitBtn = document.querySelector('#register-submit');

  const fName = document.querySelector('#register-form #register-fname').value;
  const lName = document.querySelector('#register-form #register-lname').value;
  const company = document.querySelector(
    '#register-form #register-company'
  ).value;
  const countryCode = document.querySelector(
    '#register-form #register-countrycode'
  ).value;
  const mobile = document.querySelector(
    '#register-form #register-mobile'
  ).value;
  const email = document.querySelector('#register-form #register-email').value;
  const password = document.querySelector(
    '#register-form #register-password'
  ).value;
  // const repassword = document.getElementById('register-repassword').value;
  const captcha = grecaptcha.getResponse(
    document.querySelector('#register-recaptcha').dataset.recaptchaid
  );

  if (!captcha) {
    const captchaValidation = document.getElementById(
      'artist-captcha-validation'
    );
    captchaValidation.classList.remove('d-none');
    window.setTimeout(() => {
      captchaValidation.classList.add('d-none');
    }, 5000);
    return;
  }

  // abort in 10 seconds
  let controller = new AbortController();
  const timeoutId = setTimeout(() => {
    controller.abort();
    showAlertLong('danger', 'Request Timeout');
  }, 10000);

  showSpinner(submitBtn);

  try {
    const response = await fetch(
      `${window.location.origin}/api/v1/users/register`,
      {
        signal: controller.signal,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          fName,
          lName,
          company,
          countryCode,
          mobile,
          email,
          password,
          captcha,
        }),
      }
    );

    clearTimeout(timeoutId);

    const result = await response.json();

    if (result.status === 'success') {
      // clear form inputs
      document.getElementById('register-form').reset();
      // close register form
      document.querySelector('#register .btn-close').click();
      // show alert
      showAlert('success', 'Registration Successful');
      // reload page
      reloadPage();
    } else {
      // close register form
      document.querySelector('#register .btn-close').click();
      // show alert
      showAlertLong('danger', 'Bad request. Registration failed.');
    }
  } catch (err) {
    // close register form
    document.querySelector('#register .btn-close').click();
    // Show alert
    showAlertLong(
      'danger',
      'Error: registration failed. Please try again later.'
    );
  } finally {
    // Reset Captcha
    grecaptcha.reset(
      document.querySelector('#register-recaptcha').dataset.recaptchaid
    );
    hideSpinner(submitBtn);
  }
}

export async function logout() {
  // abort in 10 seconds
  let controller = new AbortController();
  const timeoutId = setTimeout(() => {
    controller.abort();
    showAlertLong('danger', 'Request Timeout');
  }, 10000);

  try {
    const response = await fetch(
      `${window.location.origin}/api/v1/users/logout`,
      {
        signal: controller.signal,
        method: 'GET',
      }
    );

    clearTimeout(timeoutId);

    const result = await response.json();
    if (result.status === 'success') reloadPage();
  } catch (err) {
    showAlert('danger', 'Error logging out! Try again.');
  }
}

export async function setCaptcha() {
  if (!document.querySelector('#register-recaptcha').dataset.recaptchaid) {
    const recaptchaId = grecaptcha.render('register-recaptcha', {
      sitekey: '6Lcl4SweAAAAACaI_G_9sjzceyvrbVWHuP0iMWG8',
    });
    document.querySelector('#register-recaptcha').dataset.recaptchaid =
      recaptchaId;
  }
}
