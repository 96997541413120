import {
  redirectTo,
  redirectToLong,
  showAlert,
  showAlertLong,
  showSpinner,
  hideSpinner,
} from '../../utilities/utilities-ui';

// export function setupPage() {
//   if (
//     !document.querySelector('#forgotpassword #forgotpassword-captcha').dataset
//       .recaptchaid
//   ) {
//     const recaptchaId = grecaptcha.render('forgotpassword-captcha', {
//       sitekey: '6Lcl4SweAAAAACaI_G_9sjzceyvrbVWHuP0iMWG8',
//     });
//     document.querySelector(
//       '#forgotpassword #forgotpassword-captcha'
//     ).dataset.recaptchaid = recaptchaId;
//   }
// }

export async function submitForgotPasswordForm(event) {
  event.preventDefault();

  const captcha = grecaptcha.getResponse(
    document.querySelector('#forgotpassword #page-recaptcha').dataset
      .recaptchaid
  );

  if (!captcha) {
    const captchaValidation = document.querySelector(
      '#forgotpassword #forgotpassword-captcha-validation'
    );
    captchaValidation.classList.remove('d-none');
    window.setTimeout(() => {
      captchaValidation.classList.add('d-none');
    }, 5000);
    return;
  }

  const email = document.querySelector('#forgotpassword #email').value;

  let controller = new AbortController();
  const timeoutId = setTimeout(() => {
    controller.abort();
    showAlertLong('danger', 'Request Timeout');
  }, 20000);

  const submitBtn = document.querySelector(
    '#forgotpassword #forgotpassword-submit'
  );

  showSpinner(submitBtn);

  try {
    const response = await fetch(
      `${window.location.origin}/api/v1/users/forgot-password`,
      {
        signal: controller.signal,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
        }),
      }
    );

    clearTimeout(timeoutId);

    const result = await response.json();

    if (result.status === 'success') {
      // show alert
      showAlertLong(
        'success',
        'Procedure for setting new password has been mailed to you.'
      );
      // clear form inputs
      document.querySelector('#forgotpassword form').reset();
      redirectToLong('/');
    } else {
      // show alert
      showAlertLong(
        'danger',
        'There was an error mailing you the procedure to reset password. Please try again later.'
      );
    }
    redirectToLong('/');
  } catch (err) {
    console.log(`Error: ${err}`);
  } finally {
    hideSpinner(submitBtn);
  }
}
